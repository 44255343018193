export default class Meeting {
  patternId: string;
  targetDate: string;
  affiliationId: string;
  userIncode: string;
  startTime: string;
  endTime: string;
  breakTime: string;
  bikou: string;
  constructor(
    patternId = "",
    targetDate = "",
    affiliationId = "",
    userIncode = "",
    startTime = "",
    endTime = "",
    breakTime = "",
    bikou = ""
  ) {
    this.patternId = patternId;
    this.targetDate = targetDate;
    this.affiliationId = affiliationId;
    this.userIncode = userIncode;
    this.startTime = startTime;
    this.endTime = endTime;
    this.breakTime = breakTime;
    this.bikou = bikou;
  }
}
